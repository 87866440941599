<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-row>
        <b-col
          md="12"
          class="mb-3 text-right"
        >
          <b-button
            class=""
            variant="outline-primarySpore"
            @click="showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            <span class="align-middle" v-if="!show"> Mostrar filtros </span>
            <span class="align-middle" v-else > Ocultar filtros </span>
          </b-button>
        </b-col>
      </b-row>
      <b-card no-body v-if="show">
        <b-card-header class="pb-50">
          <h5>
            Filtrar
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="2"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Número"
              >
                <b-form-input
                id="number"
                v-model="filters.number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Serie"
              >
                <b-form-input
                id="serie"
                v-model="filters.serie"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Estatus"
              >
                <v-select
                v-model="status"
                :options="opcStatus"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="."
                style="color:#FFFFFF"
              >
                <b-button
                  variant="primarySpore"
                  @click="search"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                  />
                  <span>Buscar</span>
                </b-button>
                <b-button
                  class="ml-1"
                  variant="danger"
                  @click="clear"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Limpiar</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col cols="6" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ $t('Labels.Show') }}</label>
              <v-select v-model="filters.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getResponse"/>
              <label>{{ $t('Labels.Registers') }}</label>
            </b-col>
            <b-col md="6" class="mb-3 text-right">
              <b-button class="" variant="primarySpore" @click="add" v-b-tooltip.hover.top title="Instalar Gabinete">
                <feather-icon icon="PlusCircleIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <h4>Total de gabinetes: {{ totalRows }}</h4>
            </b-col>
          </b-row>
        </div>
        <div class="text-center" v-if="this.response.length === 0">
          No hay registros que mostrar
        </div>
        <b-table
          v-if="response.length > 0"
          class="position-relative"
          hover
          responsive
          primary-key="id"
          show-empty
          empty-text="No hay registros"
          :items="response"
          :fields="tableColumns"
        >
          <template #cell(number)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
              <b-avatar
                v-if="data.item.number"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/hash.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ data.item.number }}</span>
            </div>
          </template>

          <template #cell(serie)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
              <b-avatar
                v-if="data.item.serie"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/label.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ data.item.serie }}</span>
            </div>
          </template>

          <template #cell(transmited)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.transmited">
              <b-avatar
                v-if="data.item.transmited"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/fecha.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ format(data.item.transmited) }}</span>
            </div>
          </template>
          <template #cell(repeat)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
              <b-avatar
                v-if="data.item.repeat"
                size="32"
                class="avatar-border-2 box-shadow-1"
                :variant="parseInt(data.item.repeat) === 0 ? 'success' : 'warning'"
              >
                <feather-icon class="align-text-top" v-if="parseInt(data.item.repeat) === 0" icon="CheckIcon" size="18" />
                <feather-icon class="align-text-top" v-if="parseInt(data.item.repeat) !== 0" icon="AlertTriangleIcon" size="18"/>
              </b-avatar>
              <b>
                <span class="align-text-top text-capitalize ml-1" :class="parseInt(data.item.repeat) === 0 ? 'text-success' : 'text-warning'">{{ (parseInt(data.item.repeat) === 0 ? 'Correcto' : (parseInt(data.item.repeat) > 0 ? 'con duplicado' : '-')) }}</span>
              </b>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
              <b-avatar
                v-if="data.item.status"
                size="32"
                class="avatar-border-2 box-shadow-1"
                :variant="data.item.status === 'N' ? 'success' : 'warning'"
              >
                <feather-icon class="align-text-top" v-if="data.item.status === 'N'" icon="CheckIcon" size="18" />
                <feather-icon class="align-text-top" v-if="data.item.status !== 'N'" icon="AlertTriangleIcon" size="18"/>
              </b-avatar>
              <b>
                <span class="align-text-top text-capitalize ml-1" :class="data.item.status === 'N' ? 'text-success' : 'text-warning'">{{ data.item.status === 'N' ? 'Seguro' : 'Mantenimiento' }}</span>
              </b>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.filters.limit" first-class last-class class="mb-0 mt-1 mt-sm-0">
                <template #first-text>
                  <feather-icon icon="ChevronsLeftIcon" size="18"/>
                </template>
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
                <template #last-text>
                  <feather-icon icon="ChevronsRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BTable, BPagination, VBTooltip, BFormGroup, BFormInput, BAvatar, BImg, BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import request from '@/libs/request/index'
import { formatDateLanguages, empty } from '@/libs/tools/helpers'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    vSelect,
    BCardHeader,
    BFormGroup,
    BAvatar,
    BImg,
    BOverlay,
    BFormInput
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      show: false,
      loading: false,
      tableColumns: [
        { key: 'number', label: 'Número', sortable: true },
        { key: 'serie', label: 'Serie', sortable: true },
        { key: 'status', label: 'Estatus', sortable: true },
        { key: 'repeat', label: 'Duplicidad', sortable: true },
        { key: 'transmited', label: 'Transmisión', sortable: true }
      ],
      totalRows: 0,
      currentPage: 1,
      count: 0,
      perPageOptions: [10, 25, 50, 100],
      response: [],
      status: '',
      opcStatus: [
        {
          label: 'Seguro',
          value: 'N',
          title: 'Seguro'
        },
        {
          label: 'Mantenimiento',
          value: 'M',
          title: 'Mantenimiento'
        }
      ],
      filters: {
        limit: 20,
        offset: 0,
        number: '',
        serie: '',
        status: '',
        byColumn: 'transmitedAt',
        byOrder: 'asc'
      }
    }
  },
  methods: {
    async getResponse () {
      this.response = []
      if (!empty(this.status)) {
        this.filters.status = this.status.value
      }
      const params = {
        url: '/devices',
        method: 'GET',
        params: this.filters
      }
      await request(params).then(data => {
        for (let index = 0; index < data.data.data.rows.length; index++) {
          this.response.push({
            uuid: data.data.data.rows[index].uuid,
            number: data.data.data.rows[index].cfeCabinet.number,
            serie: data.data.data.rows[index].serie,
            status: data.data.data.rows[index].status,
            repeat: data.data.data.rows[index].cfeCabinet.status,
            transmited: data.data.data.rows[index].transmitedAt
          })
        }
        this.totalRows = data.data.data.count
        this.count = data.data.data.rows.length
      })
    },
    showFilter () {
      this.show = !this.show
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    add () {
      this.$router.push('/gabinets/create')
    },
    detail (uuid) {
      this.$router.push(`/gabinets/${uuid}/detail`)
    },
    search () {
      this.filters.offset = 0
      this.currentPage = 1
      this.getResponse()
    },
    clear () {
      this.filters.offset = 0
      this.currentPage = 1
      this.filters.number = ''
      this.filters.serie = ''
      this.filters.status = ''
      this.status = ''
      this.getResponse()
    }
  },
  created () {
    this.getResponse()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
